<template>
    <div class="gst-login-preview-modal u-overflow-auto-y">
        <HeaderVariant1 v-if="$vuetify.breakpoint.mdAndUp" @close="$emit('close')" />
        <HeaderVariant2 v-else
            :close-button-text="$t('_common:buttons.cancel')"
            @close="closeModal" />
        <LoginInfoContainer @login="onLoginDo" />
    </div>
</template>
<script>
    import HeaderVariant1 from '@core/shared/components/modals/layout/HeaderVariant1.vue';
    import HeaderVariant2 from '@core/shared/components/modals/layout/HeaderVariant2.vue';
    import LoginInfoContainer from '@core/shared/components/authentication/LoginInfoContainer.vue';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';

    export default {
        name: 'LoginPreviewModal',
        components: {
            HeaderVariant1,
            HeaderVariant2,
            LoginInfoContainer
        },
        mixins: [ CloseModalOnRouteChangeMixin ],
        methods: {
            onLoginDo( ) {
                this.$router.push( { name: 'auth.login', params: { method: 'redirect' } } );
                this.$emit( 'close' );
            }
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-login-preview-modal {
        background: theme-color( 'white' );

        .header-variant-1.v-sheet {
            background: theme-color( 'white' ) !important;
            border-bottom: theme-spacing( 0 );

            .v-toolbar__content {
                padding-top: theme-spacing( 2 );
                padding-right: theme-spacing( 2 );
            }
        }

        .header-variant-1__icon-close {
            height: theme-spacing( 6 );
            width: theme-spacing( 6 );
            align-self: flex-start;

            .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }

        @include mobile-only {
            .header-variant-2 {
                .spacer {
                    order: 2;
                }
            }
        }
    }
</style>
