<template>
    <div>
        <div class="gst-login-info-container__title mb-4">
            {{ $t('title', { loyaltyProgramName: loyaltyProgramName } ) }}
        </div>
        <div class="gst-login-info-container__content">
            <div v-for="item in infoItems" :key="item.key" class="d-flex align-center">
                <BaseIcon v-if="item.content" class="gst-login-info-container__content-icon mr-2 flex-shrink-0" symbol-id="icons--check_circle" />
                <div v-if="item.content">
                    {{ item.content }}
                    <p v-if="item.detail" class="gst-login-info-container__content-detail mb-1">
                        {{ item.detail }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import loyaltyConstants from '@core/utils/constants/loyalty';

    export default {
        name: 'LoginInfoContainerBody',
        components: {
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.authentication.loginInfoContainer'
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'loyaltyProgramName': 'getLoyaltyProgramName',
                'loyaltyType': 'getLoyaltyType'
            } ),
            infoItems() {
                const items = this.$t( 'content.items', { returnObjects: true } );

                return Object.keys( items ).map( key => {
                    let content = '';
                    let detail = '';
                    const item = items[key];

                    if ( typeof item === 'string' ) {
                        content = item;
                    } else if ( typeof item === 'object' ) {
                        // if loyaltyType is "none" and key is 1, save empty string to be filtered out
                        if ( key === '1' ) {
                            content = this.loyaltyType !== loyaltyConstants.TYPES.NONE ? item[ this.loyaltyType ] || '' : '';
                        } else if ( item.title && item.detail ) {
                            content = item.title;
                            detail = item.detail;
                        }
                    }

                    const result = { key, content };
                    
                    if ( detail ) {
                        result.detail = detail;
                    }

                    return result;
                } ).filter( item => item.content !== '' );
            }
        }
    };
</script>