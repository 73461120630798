<template>
    <div class="gst-login-info-container">
        <div class="gst-login-info-container__logo d-flex justify-center mt-6 mb-6 mt-md-0 mb-md-12">
            <TenantLogoIcon />
        </div>
        <LoginInfoContainerBody class="gst-login-info-container__body d-flex flex-column align-center" />
        <LoginInfoContainerFooter class="gst-login-info-container__footer" @login="$emit('login')" />
    </div>
</template>

<script>
    import LoginInfoContainerBody from './LoginInfoContainerBody.vue';
    import LoginInfoContainerFooter from './LoginInfoContainerFooter.vue';

    export default {
        name: 'LoginInfoContainer',
        components: {
            LoginInfoContainerBody,
            LoginInfoContainerFooter
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-login-info-container {
        background-color: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' );

        .gst-login-info-container__logo .gst-base-icon {
            height: auto;
            width: 100%;
            max-width: 153px;
        }

        .gst-login-info-container__title {
            line-height: font-size( 'xxxxl' );
            color: theme-color( 'quaternary' );
            font-size: font-size( 'xxxl' );
            font-weight: font-weight( 'medium' );
            text-align: center;
            max-width: 368px;
        }

        .gst-login-info-container__content {
            padding: theme-spacing( 0, 7 );

            > div {
                line-height: font-size( 'xxxl' );
                margin-top: theme-spacing( 4 );
                color: theme-color( 'quaternary' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'regular' );
            }

            > div:first-child {
                margin-top: theme-spacing( 0 );
            }

            .gst-login-info-container__content-icon {
                height: theme-spacing( 4 );
                width: theme-spacing( 4 );
                align-self: flex-start !important;
       
                .gst-svg-icon {
                    fill: theme-color( 'primary' ) !important;
                }
            }
        }

        .gst-login-info-container__footer {
            padding: theme-spacing( 0, 7, 6, 7 );

            .gst-login-info-container__login-button {
                display: block;
                height: 52px;
                width: 100%;
                margin-top: theme-spacing( 12 );

                .gst-login-btn__icon {
                    display: none;
                }
            }

            .gst-login-info-container__footer-signup-container {
                line-height: line-height( 'l' );
                margin-top: theme-spacing( 4 );
                color: theme-color( 'tertiary' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'regular' );
                text-align: center;

                .gst-sign-up-link {
                    font-weight: font-weight( 'regular' );
                }
            }
        }

        @include mobile-only {
            .gst-login-info-container__logo .gst-base-icon {
                max-width: 135px;
            }

            .gst-login-info-container__title {
                font-weight: font-weight( 'large' );
                max-width: 90%;
            }

            .gst-login-info-container__content {
                padding: theme-spacing( 0, 4 );

                > div {
                    margin-top: 14px;
                }
            }

            .gst-login-info-container__footer {
                padding: theme-spacing( 0, 4, 8, 4 );

                .gst-login-info-container__login-button {
                    margin-top: theme-spacing( 8 );
                }
            }
        }
    }
</style>
