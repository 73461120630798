<template>
    <div>
        <LoginButtonVariant1
            class="gst-login-info-container__login-button"
            @click="$emit( 'login' )" />
        <div class="gst-login-info-container__footer-signup-container">
            {{ $t('footer.guestAccountQuestion') }}
            <SignUpLink class="gst-login-info-container__signup-link" />
        </div>
    </div>
</template>

<script>
    import SignUpLink from './SignUpLink.vue';
    import LoginButtonVariant1 from './LoginButtonVariant1.vue';

    export default {
        name: 'LoginInfoContainerFooter',
        components: {
            SignUpLink,
            LoginButtonVariant1
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.authentication.loginInfoContainer'
        }
    };
</script>
