<template>
    <a :href="url" class="gst-sign-up-link">
        {{ $t('_common:buttons.signUp') }}
    </a>
</template>

<script>
    export default {
        name: 'SingUpLink',
        props: {
            url: {
                type: String,
                default: ''
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";

    .gst-sign-up-link {
        color: theme-color( 'primary' ) !important;
        font-size: font-size( 's' );
        font-weight: font-weight( 'medium' );
    }
</style>